h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6{
  font-family: "RobotoSlab", Roboto, sans-serif;
}

h1, .h1 {
  font-size: 32px;
}

h2, .h2 {
  font-size: 25px;
}

.dashboard-title{
  font-size: 20px;
}
