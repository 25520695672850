.app-audit-list{
  .audit-card{
    padding: 30px;
    background-color: $light-primary-blue;
    box-shadow: none;
  }
  .audit-list-situation{
    margin-top: 16px;

    .audit-list-situation-header{
      margin-bottom: 10px;

      &.title-only{
        margin-bottom: 28px;
      }

      .audit-list-situation-title-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 10px;

        .audit-list-situation-title{
          flex-grow: 1;
          color: $primary-color;
          margin-bottom: 0;
        }
        .audit-list-situation-title-status{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          gap: 5px;
          text-align: right;

          .audit-list-situation-title-status-date{
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
          }
          .audit-list-situation-title-status-days-left{
            .app-tag{
              font-size: 14px;
              line-height: 17px;

              &.tag-warning{
                @include tag-variant(#FFCF4A, $black);
              }
              &.tag-danger{
                @include tag-variant(#EF524F);
              }
            }
          }
        }
      }
    }
    .audit-list-situation-status-container{
      display: flex;
      align-items: center;
      gap: 15px;

      .audit-list-situation-status-jauge{
        min-width: 130px;
        max-width: 130px;

        img{
          width: 130px;
          height: 80px;
        }
      }
      .audit-list-situation-status-content{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 3px;

        .audit-list-situation-status-content-title{
          display: flex;
          align-items: flex-start;
          gap: 3px;

          .audit-list-situation-status-content-title-icon{
            min-width: 26px;
            max-width: 26px;
          }
          .audit-list-situation-status-content-title-text{
            font-weight: bold;
          }
        }
        .audit-list-situation-status-content-description{
          font-size: 14px;
          line-height: 17px;
        }
        .audit-list-situation-status-content-info{
          display: flex;
          gap: 5px;

          .audit-list-situation-status-content-info-icon{
            min-width: 15px;
            max-width: 15px;
          }
          .audit-list-situation-status-content-info-text{
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
    .audit-list-situation-recap{

      .audit-list-situation-recap-title{
        color: $primary-color;
        margin-bottom: 20px;

        .audit-list-situation-title-status-tag{
          .app-tag{
            font-size: 14px;
            line-height: 17px;
            max-width: 200px;
            margin-top: 10px;

            &.tag-warning{
              @include tag-variant(#FFCF4A, $black);
            }
            &.tag-danger{
              @include tag-variant(#EF524F);
            }
          }
        }
      }
      .audit-list-situation-recap-table{
        padding: 15px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: 14px;
        line-height: 17px;

        .audit-list-situation-recap-table-header{
          width: 100%;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 5px;

          .audit-list-situation-recap-table-title{
            text-align: center;

            &.title-id{
              text-align: left;
              padding-left: 10px;
            }
            &.title-action{
              text-align: right;
              padding-right: 10px;
            }
          }
        }
        .audit-list-situation-recap-table-rows{
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;

          .audit-list-situation-recap-table-row{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 5px;

            .audit-list-situation-recap-table-value{
              min-height: 50px;

              &.table-id{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 5px 0 5px 10px;
                gap: 5px;

                .table-id-title{
                  font-weight: bold;
                }
                .table-id-days{
                  display: flex;
                  align-items: center;
                  gap: 5px;
                }
                .table-id-status{
                  display: none;
                }
              }
              &.table-programming{
                .table-programming-card{
                  padding: 5px;
                  min-height: 50px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  gap: 5px;

                  &.card-success{
                    background-color: #25D8A1;
                    font-weight: bold;
                  }
                  &.card-warning{
                    background-color: #FFCF4A;
                    font-weight: bold;
                  }
                  &.card-danger{
                    background-color: #EF524F;
                    font-weight: bold;
                    color: $white;
                  }
                  &.card-lightgrey{
                    background-color: $light-grey;
                  }
                  &.card-grey{
                    background-color: #D9D9D9;
                    box-shadow: inset 0 0 10px 1px rgba(0, 0, 0, 0.2);
                  }
                }
              }
              &.table-action{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 10px 5px 0;

                button.ant-btn{
                  min-width: 110px;
                  max-width: 110px;
                  box-shadow: none;
                }
              }
            }

            &:nth-child(even){
              background-color: #F4F4F4;
            }
          }
        }
      }
      .audit-list-situation-recap-info{
        margin-top: 20px;
        display: flex;
        gap: 5px;

        .audit-list-situation-recap-info-icon{
          min-width: 15px;
          max-width: 15px;
        }
        .audit-list-situation-recap-info-text{
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
  .audit-list-infos{
    margin-top: 60px;

    .audit-list-infos-title{
      margin-bottom: 15px;
    }
    .audit-list-infos-texts{
      .audit-list-infos-text + .audit-list-infos-text{
        margin-top: 26px;
      }
    }
  }
  .audit-list-tips{
    margin-top: 30px;

    .audit-list-tips-title{
      margin-bottom: 20px;
    }
    .audit-list-tips-texts{
      padding: 0 20px;

      .audit-list-tips-text + .audit-list-tips-text{
        margin-top: 26px;
      }
    }
  }
  .audit-list-historic{
    margin-top: 60px;

    .audit-list-historic-title{
      color: $primary-color;
      margin-bottom: 30px;
    }
    .audit-list-historic-captions{
      margin-top: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px 15px;

      .audit-list-historic-captions-title{
        font-size: 14px;
        line-height: 17px;
      }
      .audit-list-historic-caption{
        display: flex;
        align-items: center;
        gap: 9px;

        .audit-list-historic-caption-circle{
          min-width: 12px;
          max-width: 12px;
          height: 12px;
          border-radius: 50%;

          &.circle-success{
            background-color: #25D8A1;
          }
          &.circle-warning{
            background-color: #FFCF4A;
          }
          &.circle-danger{
            background-color: #EF524F;
          }
        }
        .audit-list-historic-caption-text{
          font-size: 14px;
          line-height: 17px;
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: $max-md-breakpoint){
    .audit-card{
      padding: 20px;

      &.card-no-margin-mobile{
        margin: 0 -20px;
      }
    }
    .audit-list-situation{
      .audit-list-situation-header{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 40px;

        &.title-only{
          margin-bottom: 40px;
        }

        .audit-list-situation-title-container{
          flex-grow: 1;
          justify-content: flex-start;
          flex-direction: column;

          .audit-list-situation-title-status{
            align-items: flex-start;
            text-align: left;
          }
        }
      }
    }
    .audit-list-infos{
      margin-top: 30px;
    }
    .audit-list-tips{
      .audit-list-tips-texts{
        padding: 0;
      }
    }
    .audit-list-historic{
      margin-top: 30px;

      .audit-list-historic-title{
        margin-bottom: 20px;
      }
      .audit-list-historic-captions{
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
      }
    }
  }

  @media (max-width: $max-lg-breakpoint){
    .audit-list-situation{
      .audit-list-situation-status-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }
    }
  }

  @media (max-width: $max-xl-breakpoint){
    .audit-list-situation{
      .audit-list-situation-recap{
        .audit-list-situation-recap-table{
          .audit-list-situation-recap-table-header{
            grid-template-columns: 1fr;

            .audit-list-situation-recap-table-title{
              &.title-id{
                text-align: center;
                padding-left: 0;
              }
              &.title-programming{
                display: none;
              }
              &.title-action{
                display: none;
              }
            }
          }
          .audit-list-situation-recap-table-rows{
            .audit-list-situation-recap-table-row{
              display: flex;

              .audit-list-situation-recap-table-value{
                &.table-id{
                  flex-grow: 1;

                  .table-id-status{
                    display: block;

                    .status-success{
                      color: #25D8A1;
                    }
                    .status-canceled{
                      display: flex;
                      align-items: center;
                      gap: 5px;
                    }
                  }
                }
                &.table-programming{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.app-modal-detail-installation.app-modal.ant-modal {
  width: 1200px !important;
  max-width: 100%;

  .audit-detail-installations {
    .audit-detail-installations-items {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;

      .audit-detail-installations-item {
        max-width: 290px;
        width: 100%;
        padding: 20px;
        border: 1px solid $grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .audit-detail-installations-item-choice {
          color: $grey;
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 5px;
        }

        .audit-detail-installations-item-name,
        .audit-detail-installations-item-number {
          font-size: 14px;
          line-height: 17px;
        }

        .audit-detail-installations-item-date {
          margin-top: 5px;
        }

        .audit-detail-installations-item-rgi {
          margin-top: 5px;
          color: $grey;
          font-size: 14px;
          line-height: 17px;
        }

        .audit-detail-installations-item-address {
          flex-grow: 1;
          margin-top: 10px;
        }

        .audit-detail-installations-item-action {
          margin-top: 20px;
          padding: 10px 15px;
          border-radius: 5px;
          font-size: 18px;
          line-height: 26px;
          box-shadow: none;
        }
      }
    }
  }
}

.app-modal-confirm-installation.app-modal.ant-modal{
  width: 480px !important;

  .ant-modal-content{
    .ant-modal-header{
      padding: 15px 40px 10px;

      .ant-modal-title{
        font-size: 20px;
        line-height: 25px;
        text-align: left;
      }
    }
    .ant-modal-body{
      font-size: $font-size;

      .confirm-installation-item-container{
        display: flex;
        justify-content: center;

        .confirm-installation-item{
          max-width: 211px;
          width: 100%;
          padding: 20px;
          border: 1px solid $grey;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          .confirm-installation-item-choice{
            color: $grey;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
          }
          .confirm-installation-item-name,
          .confirm-installation-item-number{
            font-size: 12px;
            line-height: 14px;
          }
          .confirm-installation-item-rgi{
            margin-top: 5px;
            color: $grey;
            font-size: 12px;
            line-height: 14px;
          }
          .confirm-installation-item-address{
            flex-grow: 1;
            margin-top: 10px;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .confirm-installation-item-container + .confirm-installation-text{
        margin-top: 30px;
      }
    }
    .ant-modal-footer{
      padding: 10px 40px 10px;
    }
  }
}