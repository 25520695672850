header{
  background-color: $header-bg;
  height: $header-height;

  .logo{
    height: $header-logo-size;
  }

  .user{
    .user-name{
      font-size: 1rem;
      line-height: 1.1rem;
      font-weight: 600;
    }
    .user-logout{
      font-size: .9rem;
      line-height: 1.1rem;
    }
    .user-avatar{
      height: $header-avatar-size;
      width: $header-avatar-size;

      .ant-avatar{
        height: $header-avatar-size;
        width: $header-avatar-size;
      }
    }
  }
}
