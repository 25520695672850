.app-breadcrumbs.ant-breadcrumb{
  position: absolute;
  top: .5rem;
  left: 1.5rem;

  @media (max-width: $max-sidemenu-breakpoint){
    &.is-side-menu{
      margin-left: 40px;
    }
  }

  .ant-breadcrumb-link{
    text-decoration: none !important;
  }
}
