.cncc-richtext-wrapper{
  .rdw-option-wrapper{
    box-sizing: initial;
  }

  .rdw-editor-toolbar{
    border: 1px solid $disabled-form-color;
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  a.rdw-dropdown-selectedtext{
    color: $black;
  }

  .rdw-fontsize-dropdown{
    min-width: 50px;
  }

  .rdw-link-modal{
    height: auto;
  }

  .rdw-dropdown-optionwrapper,
  .rdw-colorpicker-modal-options,
  .rdw-editor-main.cncc-richtext-editor{
    overflow: auto;
  }

  .rdw-editor-main.cncc-richtext-editor {
    border: 1px solid $disabled-form-color;
    padding: 5px;
    border-radius: 0;
    height: 300px;


    .public-DraftEditor-content h1,
    .public-DraftEditor-content h2,
    .public-DraftEditor-content h3,
    .public-DraftEditor-content h4,
    .public-DraftEditor-content h5,
    .public-DraftEditor-content h6,
    .public-DraftEditor-content a,
    .public-DraftEditor-content ul,
    .public-DraftEditor-content ol,
    .public-DraftEditor-content li{
      color: black;
      box-sizing: unset;
      line-height: 1.15;
    }

    .public-DraftEditor-content h1,
    .public-DraftEditor-content h2,
    .public-DraftEditor-content h3,
    .public-DraftEditor-content h4,
    .public-DraftEditor-content h5,
    .public-DraftEditor-content h6{
      display: block;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-weight: bold;
    }

    .public-DraftEditor-content h1{
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
    }
    .public-DraftEditor-content h2{
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
    }
    .public-DraftEditor-content h3{
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
    .public-DraftEditor-content h4{
      font-size: 1em;
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
    }
    .public-DraftEditor-content h5{
      font-size: 0.83em;
      margin-block-start: 1.67em;
      margin-block-end: 1.67em;
    }
    .public-DraftEditor-content h6{
      font-size: 0.67em;
      margin-block-start: 2.33em;
      margin-block-end: 2.33em;
    }
    .public-DraftEditor-content a{
      cursor: text;
      color: -webkit-link;
      text-decoration: underline;
    }
    .public-DraftEditor-content ul{
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
      margin: 16px 0;
      padding: 0;
    }
    .public-DraftEditor-content ol{
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
      margin: 16px 0;
      padding: 0;
    }
    .public-DraftEditor-content li{
      margin-left: 1.5em;
      position: relative;
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }

  .DraftEditor-root{
    height: auto;
    min-height: 250px;
  }

  &.cncc-richtext-wrapper-readonly{
    cursor: not-allowed;
    .rdw-editor-toolbar{
      display: none;
    }
    .rdw-editor-main.cncc-richtext-editor{
      background-color: $disabled-form-bg-color;
    }
  }
}
