.ant-table-thead > tr > th .ant-table-filter-trigger-container{
  background-color: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort .ant-table-filter-trigger-container{
  background-color: #f5f5f5;
}

.ant-table {
  overflow-x: auto !important;
}

.app-contact-pro-table{
  .ant-table{
    .ant-table-container{
      .ant-table-content{
        table{
          border: none;
          border-bottom: none;
          .ant-table-thead{
            tr{
              th.ant-table-cell{
                border-bottom: none;
                background-color: $light-secondary-blue;
                padding: 10px 20px;
                font-size: 14px;
                line-height: 17px;

                &:before{
                  content: none;
                }
                &.qualificationDeadline{
                  text-align: right;
                }
                &.ant-table-selection-column{
                  padding: 0;

                  .ant-checkbox-wrapper{
                    font-size: 16px;
                    line-height: 16px;
                    align-items: center;

                    .ant-checkbox{
                      font-size: 16px;
                      line-height: 16px;
                      top: 0;
                    }
                  }
                }
              }
            }
          }
          .ant-table-tbody{
            tr{
              td.ant-table-cell{
                border-bottom: $border-width solid $grey;
              }

              &:last-child{
                td.ant-table-cell{
                  border-bottom: none !important;
                }
              }

              &.ant-table-row {
                cursor: pointer;

                td.ant-table-cell {
                  border-bottom: $border-width solid $grey;
                  background-color: $white;
                  padding: 12px 20px;
                  font-size: 14px;
                  line-height: 17px;

                  &.ant-table-selection-column{
                    padding: 0;

                    .ant-checkbox-wrapper{
                      font-size: 16px;
                      line-height: 16px;
                      align-items: center;

                      .ant-checkbox{
                        font-size: 16px;
                        line-height: 16px;
                        top: 0;
                      }
                    }
                  }

                  &.name {
                    font-family: "CenturyGothic", Roboto, sans-serif;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                  }

                  .app-tag {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: bold;
                    padding: 4px 12px;
                    border-radius: 25px;
                  }

                  &.remark {
                    font-size: 18px;
                    line-height: 26px;
                  }

                  &.status {
                    text-align: center;
                  }

                  &.updated {
                    text-align: right;
                  }

                  &.audit-date{
                    font-weight: bold;
                  }

                  &.audit-result{
                    .app-tag{
                      font-size: 14px;
                      line-height: 17px;
                      font-weight: bold;
                      border-radius: 13px;

                      &.tag-success{
                        @include tag-variant(#25D8A1);
                      }
                      &.tag-warning{
                        @include tag-variant(#FFCF4A, $black);
                      }
                      &.tag-danger{
                        @include tag-variant(#EF524F);
                      }
                    }
                  }
                }

                &.unread, &.init {
                  td.ant-table-cell {
                    background-color: $light-secondary-blue;
                    color: $light-primary-blue;

                    &.remark {
                      font-weight: bold;
                    }

                    &.status {
                      .status-container {
                        background-color: $anthracite;
                        color: $white;
                        padding: 5px 12px;
                      }
                    }
                  }
                }

                &.read, &.processed, &.sent{
                  td.ant-table-cell {

                    &.status {
                      .status-container {
                        background-color: $light-primary-blue;
                        padding: 5px 12px;
                      }
                    }
                  }
                }

                &.concluded, &.eligible, &.grdf  {
                  td.ant-table-cell {
                    background-color: $light-grey;

                    &.status {
                      .status-container {
                        background-color: $white;
                        padding: 5px 12px;
                      }
                    }
                  }
                }
                &.abandoned, &.ineligible {
                  td.ant-table-cell {
                    background-color: $light-grey;

                    &.status {
                      .status-container {
                        background-color: $white;
                        padding: 5px 12px;
                      }
                    }
                  }
                }
                &.new {
                  td.ant-table-cell {
                    font-family: "CenturyGothic", Roboto, sans-serif;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.pro-table-large-font{
    .ant-table{
      .ant-table-container{
        .ant-table-content{
          table{
            .ant-table-tbody{
              tr{
                &.ant-table-row {
                  td.ant-table-cell {
                    font-size: 16px;
                    line-height: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.pro-table-cursor-default{
    .ant-table {
      .ant-table-container {
        .ant-table-content {
          table {
            .ant-table-tbody {
              tr {
                &.ant-table-row {
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}
