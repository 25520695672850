.list-limit {
  .text {
    @media screen and (max-width: $max-sm-breakpoint) {
      display: none;
    }
  }
}
.list-filter-search.ant-list{
  .ant-list-items{
    .ant-list-item{
      background-color: $grey;
      border: 0;
      margin-bottom: 10px;
      padding: 24px;

      .logo{
        min-width: 150px;
        max-width: 150px;
        height: 150px;
        border-radius: $border-radius;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img{
          max-width: 100%;
          max-height: 100%;
        }

        .anticon {
          svg {
            width: 30px;
            height: 30px;
            fill: $primary-color;
          }
        }
      }

      .name{
        color: $primary-color;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 22px;

        &.company {
          color: $secondary-color;
        }
      }

      .address{
        font-weight: 500;

        .anticon{
          font-size: 24px;
        }
      }

      .activity-sector{
        .activity-title{
          font-weight: bold;
          text-transform: uppercase;
          font-size: 14px;
        }
        .activity-card{
          background-color: $primary-color;
          color: $white;
          padding: 6px 18px;
          font-size: 14px;

          &.company {
            background-color: $secondary-color;
          }
        }
      }

      .naf-code,
      .turnover{
        font-size: 14px;
      }

      .actions{
        border-top: 2px solid $white;
        margin: 0 -28px -25px !important;

        button.ant-btn {
          min-height: 40px;
          box-shadow: none;
          font-size: 13px;
          line-height: 17px;
          padding: 0 5px;
        }
      }
    }
  }

  .ant-list-pagination{
    text-align: center;
  }
}

.ant-list-items{
  .ant-card.app-card {
    &.card-notification {
      margin-bottom: 10px;
    }
  }
}

.ant-list {
  &.has-to-delete-1 {
    .ant-list-items {
      min-height: 205px;
    }
  }
  &.has-to-delete-2 {
    .ant-list-items {
      min-height: 410px;
    }
  }
  &.has-to-delete-3 {
    .ant-list-items {
      min-height: 616px;
    }
  }
}
