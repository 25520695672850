/* COULEURS */
// Certaines couleurs de base de antd sont dans config-overrides.js
$primary-color: #405faf;
$secondary-color: #e98300;
$tertiary-color: #051e5b;
$success-color: #00b2a9;
$warning-color: #fecb00;
$danger-color: #e2003c;
$disabled-color: #888888;
$disabled-form-color: #c4c4c4;
$disabled-form-bg-color: #f5f5f5;
$dark-grey: #a6aaab;
$light-grey: #ECECEC;
$grey: #d6dadb;
$white: #ffffff;
$black: #000000;
$primary-blue: #0F4291;
$secondary-blue: #4197CB;
$light-primary-blue: #E7EDF4;
$light-secondary-blue: #ECF5FA;
$primary-green: #6BA43A;
$secondary-green: #456926;
$light-primary-green: #E1EDD8;
$light-secondary-green: #DAE1D4;
$anthracite: #232323;

/* BORDER */
$border-radius: .3em;

/* BOX SHADOW */
$box-shadow: 0 0 10px 0 rgba(0,32,91,0.2);
$box-shadow-card: 0 2px 17px rgba(0, 0, 0, 0.18);

/* BREAKPOINTS */
$max-xl-breakpoint: 1199.98px; // Extra large devices (large desktops, 1200px and up)
$max-lg-breakpoint: 991.98px;  // Large devices (desktops, 992px and up)
$max-md-breakpoint: 767.98px;  // Medium devices (tablets, 768px and up)
$max-sm-breakpoint: 575.98px;  // Small devices (landscape phones, 576px and up)
$max-xs-breakpoint: 399.98px;

$xl-breakpoint: 1200px; // Extra large devices (large desktops, 1200px and up)
$lg-breakpoint: 992px;  // Large devices (desktops, 992px and up)
$md-breakpoint: 768px;  // Medium devices (tablets, 768px and up)
$sm-breakpoint: 576px;  // Small devices (landscape phones, 576px and up)

/* BODY */
$body-bg: #efefef;
$font-color: #000000;
$font-size: 16px;
$line-height: 19px;
$letter-spacing: normal;
$container-max-width: 1200px;

/* SCROLLBAR */
$scrollbar-color: #a9a9a9;
$scrollbar-width: 10px;

/* HEADER */
$header-bg: #ffffff;
$header-height: 60px;
$header-avatar-size: 42px;
$header-logo-size: 40px;

/* SIDEMENU */
$sidemenu-breakpoint: $lg-breakpoint;
$max-sidemenu-breakpoint: $max-lg-breakpoint;
$sidemenu-bg: #051e5b;
$sidemenu-width: 250px;
$sidemenu-link-selected-bg: $primary-color;
$sidemenu-text-color: $white;
$sidemenu-text-selected-color: $white;
