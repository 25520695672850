.app-resources{
  .resources-form{
    @media screen and (min-width: $lg-breakpoint) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 24px;

      .resources-form-item{
        flex: 1 0 calc(50% - 12px);

        .ant-form-item.csn-upload{
          margin-bottom: 0;
        }
      }
    }
  }
}
