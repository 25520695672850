.app-card{
  background-color: $white;
  border-radius: $border-radius;
  border: $border-width solid $white;
  padding: 24px;

  &.card-hover:hover{
    border-color: darken($white, 15%);
    cursor: pointer;
  }

  &.app-card-qualifications {
    padding: 20px;
    box-shadow: $box-shadow-card;

    .card-qualifications-header{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 16px;
      padding-bottom: 24px;
      border-bottom: 1px solid $grey;
      margin-bottom: 24px;

      .card-qualifications-title {
        flex-grow: 1;
        margin-bottom: 0;
        color: $primary-color;
      }
      .card-qualifications-status{
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        line-height: 19px;
        color: $black;
      }
    }
    .card-qualifications-items{
      margin: 0 12px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
      gap: 10px 24px;

      @media screen and (max-width: $max-xs-breakpoint) {
        grid-template-columns: 1fr;
      }
      @media screen and (min-width: $max-xl-breakpoint) {
        min-height: 166px;
      }

      .app-qualification-item{

      }
    }
    .card-qualifications-infos{
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 16px;

      .card-qualifications-infos-text{
        flex-grow: 1;
        color: $primary-blue;
        margin-bottom: 0;
      }
      .card-qualifications-infos-button{
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: "CenturyGothic", Roboto, sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: $primary-blue;
        cursor: pointer;
        padding: 2.5px;
      }
    }
  }
}

.dashboard-card{
  width: 300px;
  border: 2px solid $disabled-form-color;
  background-color: $white;
  color: $black;
  border-radius: $border-radius;

  &:hover{
    border-color: #6480bd;
    color: #6480bd;
  }

  &.dashboard-card-primary{
    border: 2px solid $primary-color;
    background-color: $primary-color;
    color: $white;

    &:hover{
      background-color: $white;
      color: $primary-color;
    }
  }

  &.dashboard-card-secondary{
    border: 2px solid $secondary-color;
    background-color: $secondary-color;
    color: $white;

    &:hover{
      background-color: $white;
      color: $secondary-color;
    }
  }

  &.dashboard-card-tertiary{
    border: 2px solid $tertiary-color;
    background-color: $tertiary-color;
    color: $white;

    &:hover{
      background-color: $white;
      color: $tertiary-color;
    }
  }

  .anticon{
    font-size: 36px;
  }
  .dashboard-card-text{
    font-size: 21px;
    text-align: center;
  }

  &.dashboard-card-primary,
  &.dashboard-card-secondary,
  &.dashboard-card-tertiary{
    .anticon{
      font-size: 48px;
    }
  }
}
