$font-path: '../../static/fonts/';

@font-face {
  font-family: "Lato";
  src: url($font-path + "Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "RobotoSlab";
  src: url($font-path + "RobotoSlab-Regular.ttf") format("truetype");
  font-weight: normal;
}
