.bg-primary{
  background-color: $primary-color !important;
}
.bg-secondary{
  background-color: $secondary-color !important;
}
.bg-tertiary{
  background-color: $tertiary-color !important;
}
.bg-success{
  background-color: $success-color !important;
}
.bg-warning{
  background-color: $warning-color !important;
}
.bg-danger{
  background-color: $danger-color !important;
}
.bg-black{
  background-color: $black !important;
}
.bg-white{
  background-color: $white !important;
}
.bg-grey{
  background-color: $grey !important;
}
.bg-dark-grey{
  background-color: $dark-grey !important;
}
