.app-alert{
  border-radius: $border-radius;
  border: $border-width solid $black;
  background-color: transparent !important;
  padding: 24px;

  .icon{
    svg{
      height: 30px;
      width: 30px;
    }
  }

  &.alert-primary{
    @include alert-variant($primary-color);
  }
  &.alert-secondary{
    @include alert-variant($secondary-color);
  }
  &.alert-tertiary{
    @include alert-variant($tertiary-color);
  }
  &.alert-success{
    @include alert-variant($success-color);
  }
  &.alert-warning{
    @include alert-variant($warning-color);
    background-color: lighten($warning-color, 45%) !important;
  }
  &.alert-danger{
    @include alert-variant($danger-color);
  }
}

.Toastify{
  .Toastify__toast-container{
    .Toastify__toast{
      position: relative;
      padding: 15px 20px;
      border-radius: $border-radius;
      font-weight: bold;

      .Toastify__close-button{
        position: absolute;
        top: 0;
        right: 5px;
        outline: none !important;
      }

      &.Toastify__toast--info {
        @include toast-variant($primary-color);
      }
      &.Toastify__toast--success {
        @include toast-variant($success-color);
      }
      &.Toastify__toast--error {
        @include toast-variant($danger-color);
      }
    }
  }
}
