.app-pg-subscribers-tags{
  display: flex;
  align-items: center;
  gap: 15px;

  .pg-subscribers-tag{
    min-width: 107px;
    max-width: 107px;
  }
}
.app-rg-tags{
  display: flex;
  align-items: center;
  gap: 15px;

  .rg-tag{
    min-width: 73px;
    max-width: 73px;
  }
}
