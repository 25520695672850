.app-sidemenu-overlay{
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0,0,0,0.3);

  @media (min-width: $sidemenu-breakpoint){
    display: none !important;
  }
}

.app-sidemenu{
  background-color: $sidemenu-bg;
  max-width: $sidemenu-width;
  min-width: $sidemenu-width;

  &.mobile-sidemenu-open{
    left: 0;
  }

  @media (max-width: $max-sidemenu-breakpoint){
    position: absolute;
    top: $header-height;
    bottom: 0;
    left: -$sidemenu-width;
    transition: left 0.5s;
    z-index: 10;
  }

  .btn-side-menu{
    position: absolute;
    top: 0;
    right: -36px;

    @media (min-width: $sidemenu-breakpoint){
      display: none !important;
    }
  }

  .sidemenu-container {
    max-height: calc(100vh - #{$header-height});
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .link{
      padding: 20px 20px;
      height: 78px;
      text-decoration: none;

      &.link-active{
        background-color: $sidemenu-link-selected-bg;
        color: $sidemenu-text-selected-color;
      }

      .icon svg{
        margin-right: 10px;
        height: 24px;
        width: 24px;
        fill: $sidemenu-text-color;

        path{
          fill: $sidemenu-text-color;
        }
      }

      .text{
        color: $sidemenu-text-color;
        line-height: normal;
      }

      &:hover{
        text-decoration: none;
      }

      &:hover,
      &.selected{
        background-color: $sidemenu-link-selected-bg;
        .icon svg{
          fill: $sidemenu-text-selected-color;

          path{
            fill: $sidemenu-text-selected-color;
          }
        }

        .text{
          color: $sidemenu-text-selected-color;
        }
      }
    }
    .ant-collapse.sidemenu-link-collapse{
      border: none;
      background-color: $sidemenu-bg;

      .icon svg{
        margin-right: 10px;
        height: 24px;
        width: 24px;
        fill: $sidemenu-text-color;

        path{
          fill: $sidemenu-text-color;
        }
      }

      .ant-collapse-item{
        border: none;
        .ant-collapse-header{
          padding: 15px 44px 15px 25px;
          color: $sidemenu-text-color;
          border-radius: 0;
          line-height: $line-height;

          .ant-collapse-arrow{
            right: 20px;
          }

          &:hover,
          &.selected{
            background-color: $sidemenu-link-selected-bg ;
            color: $sidemenu-text-selected-color;
          }
        }
        .ant-collapse-content{
          border: none;
          background-color: $sidemenu-link-selected-bg;
          border-radius: 0;
          color: $sidemenu-text-color;

          .ant-collapse-content-box{
            padding: 15px 25px 15px 35px;
            .collapse-link{
              &:not(:last-child){
                margin-bottom: 20px;
              }

              a{
                color: $sidemenu-text-selected-color;
                text-decoration: none;

                &:hover{
                  text-decoration: underline;
                }
              }

              &.collapse-link-active{
                a{
                  text-decoration: underline;
                }
              }
            }
          }
        }
        &.ant-collapse-item-active{
          .ant-collapse-header{
            background-color: $sidemenu-link-selected-bg ;
            color: $sidemenu-text-selected-color ;
          }
        }
      }

      &.sidemenu-link-collapse-active{
        .ant-collapse-item:not(.ant-collapse-item-active){
          .ant-collapse-header{
            background-color: $sidemenu-link-selected-bg;
            color: $sidemenu-text-selected-color !important;
          }
        }
      }
    }
  }
}
