/* BUTTONS */
@mixin btn-variant($background-color, $border-color: $background-color, $text-color: $white) {
  background-color: $background-color !important;
  border-color: $border-color !important;
  color: $text-color !important;

  svg{
    fill: $text-color !important;

    path{
      fill: $text-color !important;
    }
  }

  &:hover{
    background-color: $text-color !important;
    border-color: $border-color !important;
    color: $background-color !important;

    svg{
      fill: $background-color !important;

      path{
        fill: $background-color !important;
      }
    }
  }
}

@mixin btn-variant-lighten($background-color, $border-color: $background-color, $text-color: $white) {
  background-color: $background-color !important;
  border-color: $border-color !important;
  color: $text-color !important;

  svg{
    fill: $text-color !important;

    path{
      fill: $text-color !important;
    }
  }

  &:hover{
    background-color: lighten($background-color, 15%) !important;
    border-color: lighten($border-color, 15%) !important;
    color: lighten($text-color, 15%) !important;

    svg{
      fill: lighten($text-color, 15%) !important;

      path{
        fill: lighten($text-color, 15%) !important;
      }
    }
  }
}

@mixin btn-variant-darken($background-color, $border-color: $background-color, $text-color: $white) {
  background-color: $background-color !important;
  border-color: $border-color !important;
  color: $text-color !important;

  svg{
    fill: $text-color !important;

    path{
      fill: $text-color !important;
    }
  }

  &:hover{
    background-color: darken($background-color, 15%) !important;
    border-color: darken($border-color, 15%) !important;
    color: darken($text-color, 15%) !important;

    svg{
      fill: darken($text-color, 15%) !important;

      path{
        fill: darken($text-color, 15%) !important;
      }
    }
  }
}

/* BADGES */
@mixin badge-variant($background-color, $text-color: $white) {
  background-color: $background-color;
  color: $text-color;
}

/* TAGS */
@mixin tag-variant($background-color, $text-color: $white) {
  background-color: $background-color;
  color: $text-color;
}

/* DIVIDERS */
@mixin divider-variant($background-color) {
  background-color: $background-color;
}

/* ALERTS */
@mixin alert-variant($color) {
  border-color: $color;

  .icon{
    svg{
      fill: $color;

      path{
        fill: $color;
      }
    }
  }
  .text{
    color: $color;
  }
}

/* TOASTS */
@mixin toast-variant($background-color) {
  background: $background-color;
}
