button.ant-btn{
  height: auto !important;
  padding: .5rem 1rem;
  border-radius: $border-radius;
  border: $border-width solid;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  transition: none;
  outline: none !important;
  box-shadow: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: $line-height;
  }
}

.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:active,
.btn-primary, .btn-primary:focus, .btn-primary:active {
  @include btn-variant($primary-color, $primary-color, $white);
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:active {
  @include btn-variant($secondary-color, $secondary-color, $white);
}

.btn-tertiary, .btn-tertiary:focus, .btn-tertiary:active {
  @include btn-variant($tertiary-color, $tertiary-color, $white);
}

.btn-success, .btn-success:focus, .btn-success:active {
  @include btn-variant($success-color, $success-color, $white);
}

.btn-warning, .btn-warning:focus, .btn-warning:active {
  @include btn-variant($warning-color, $warning-color, $white);
}

.btn-danger, .btn-danger:focus, .btn-danger:active {
  @include btn-variant($danger-color, $danger-color, $white);
}

.btn-black, .btn-black:focus, .btn-black:active {
  @include btn-variant($black, $black, $white);
}

.btn-white, .btn-white:focus, .btn-white:active {
  @include btn-variant-darken($white, $white, $black);
}

.btn-grey, .btn-grey:focus, .btn-grey:active {
  @include btn-variant-darken($grey, $grey, $black);
}

.btn-dark-grey, .btn-dark-grey:focus, .btn-dark-grey:active {
  @include btn-variant-darken($dark-grey, $dark-grey, $black);
}

.btn-transparent, .btn-transparent:focus, .btn-transparent:active {
  @include btn-variant-lighten(transparent, transparent, $black);
}

.btn-side-menu, .btn-side-menu:focus, .btn-side-menu:active {
  border-radius: 0 0 18px 0 !important;
  min-height: 36px !important;
  max-width: 36px !important;
  min-width: 36px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sidemenu-bg !important;
  border-color: $sidemenu-bg !important;
  color: $white !important;

  svg{
    fill: $white !important;

    path{
      fill: $white !important;
    }
  }

  &:hover{
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $white !important;

    svg{
      fill: $white !important;

      path{
        fill: $white !important;
      }
    }
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $secondary-color !important;
  color: $white !important;
}

.ant-radio-button-wrapper {
  color: $disabled-color !important;
}

.ant-btn[disabled] {
  background-color: $disabled-color !important;
  border-color: $disabled-color !important;
  color: $white !important;

  svg{
    fill: $white !important;

    path{
      fill: $white !important;
    }
  }
}
.ant-switch-checked {
  background-color: $secondary-color !important;
}

button.ant-btn.btn-list{
  height: 40px !important;
}

button.ant-btn.btn-search{
  height: 41px !important;
  border-radius: 0 !important;
}

.dropdown-button-success{
  button{
    @include btn-variant($success-color, $success-color, $white);
  }
}

.dropdown-button-secondary{
  button{
    @include btn-variant($secondary-color, $secondary-color, $white);
  }
}

button.ant-btn.download-csv-button{
  font-family: "Lato", Roboto, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  padding: 0;
  border: none;

  .icon{
    height: 19px;
  }
}

.tree-button {
  max-height: 30px;
  max-width: 30px;
  margin-left: 5px;
}

.tree-link {
  color: $secondary-blue;
  text-decoration: none !important;
  margin-left: 5px;
  margin-right: 5px;
}
