.qualifications-cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: stretch;
  margin-bottom: 25px;
  margin-top: 20px;

  @media screen and (max-width: $max-xl-breakpoint) {
    grid-template-columns: 1fr;
  }
}

.ant-dropdown.app-dropdown-qualifications{
  max-width: 234px;

  .ant-dropdown-menu{
    .ant-dropdown-menu-item{
      white-space: pre-wrap;
    }
  }
}
