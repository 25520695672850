.app-badge{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 5px;
  @include badge-variant($black);

  &.badge-primary{
    @include badge-variant($primary-color);
  }
  &.badge-secondary{
    @include badge-variant($secondary-color);
  }
  &.badge-tertiary{
    @include badge-variant($tertiary-color);
  }
  &.badge-success{
    @include badge-variant($success-color);
  }
  &.badge-warning{
    @include badge-variant($warning-color, $black);
  }
  &.badge-danger{
    @include badge-variant($danger-color);
  }
  &.badge-white{
    @include badge-variant($white, $black);
  }
  &.badge-grey{
    @include badge-variant($grey, $black);
  }
  &.badge-dark-grey{
    @include badge-variant($dark-grey, $black);
  }
}
