/* width */
::-webkit-scrollbar {
  width: $scrollbar-width;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  background: $body-bg;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
  border-radius: calc(2 * #{$scrollbar-width});
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($scrollbar-color, 10%);
}
